import Vue from "vue";
import Vuetify from "vuetify/lib";

import en from "@/locale/en";
import zhHans from "vuetify/es5/locale/zh-Hans";
import colors from "vuetify/lib/util/colors";
Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { en, zhHans },
    current: "en"
  },
  icons: {
    iconfont: "mdi"
  },
  theme: {
    dark: true,
    defaultTheme: "dark",
    options: { customProperties: true },

    themes: {
      light: {
        ipsos: "#009d9c",
        ipsosPurple: "#3948a4",
        primary: "#009d9c",
        //sidebar: colors.grey.lighten4,
        tertiary: colors.grey.lighten4,
        lightHeavy: colors.grey.lighten3,
        pageHeader: colors.grey.darken3

        // secondary: colors.grey.darken4,
        // pageHeader: "#333333"
        // background: "#F5F5F5",
        // background2: "#FAFAFA"
      },
      dark: {
        ipsos: "#009d9c",
        ipsosPurple: "#3948a4",
        // primary: "#1976D2",
        primary: "#009d9c",
        tertiary: colors.grey.darken4,
        lightHeavy: colors.grey.darken3,

        // pageHeader: "#000",
        // sidebar: colors.grey.darken4,
        pageHeader: colors.darken4
        // background: "#121212",
        // background2: "#000000"
      }
    }
  }
});
