import en from "vuetify/lib/locale/en";
export default {
  ...en,
  app: {
    myStudies: "My Studies",
    myProjects: "My Projects"
  },
  router: {
    wave: "Wave",
    home: "Home",
    studies: "Studies",
    projects: "Projects",
    authFailed: "Authentication Failed",
    authRegionDenied: "Regional Access Denied",
    study: "Study",
    projectDetails: "Project Details",
    invitations: "Invitations",
    invitation: "Invitation",
    fieldwork: "Fieldwork",
    fieldworkWave: "Fieldwork",
    privacyPolicies: "Privacy Policies",
    quota: "Quota",
    waves: "Waves",
    sampleFiles: "Sample Files",
    sampleFile: "Sample File",
    schedule: "Schedule",
    sampleVerification: "Sample Verification",
    sampleExplorer: "Sample Explorer",
    survey: "Survey",
    invitationQueue: "Invitation Queue",
    studyInvitationQueue: "Study Invitation Queue",
    sampleFileInvitationQueue: "Invitation Queue",
    campaigns: "Campaigns",
    campaign: "Campaign",
    allActiveCampaigns: "All Active Campaigns",
    assetCollections: "Asset Collections",
    studyQuotaGroups: "Quota Groups",
    studyQuotas: "Quotas",
    studyAssets: "Study Assets",
    dataModeler: "Data Modeler",
    curations: "Curations",
    featureMatrix: "Feature Matrix",
    featureMatrixDefinition: "Feature Matrix Definition",
    domains: "Domains",
    studyDomains: "Domains",
    dataPatches: "Data Patches",
    htmlDocuments: "Documents",
    htmlDocument: "Document",
    emailTest: "Email Tester",
    surveys: "Surveys",
    surveyMetadata: "Survey Metadata",
    dataProcessing: "Data Processing",
    projectTemplates: "Project Templates",
    projectTemplate: "Project Template",
    studyTemplates: "Study Templates",
    studyTemplate: "Study Template",
    outlierReview: "Outlier Review",
    imageQuestions: "Image Questions"
  },
  surveysView: {
    noSurveys: "There are no surveys to display."
  },
  homeView: {
    noStudies: "There are no studies to display.",
    noProjects: "There are no projects to display."
  },
  sampleFileProcess: {
    processSample: "Process Sample"
  },
  sampleFileStepper: {
    location: "Location",
    dateUploaded: "Date Uploaded",
    dateProcessed: "Date Processed",
    tags: "Tags",
    quota: "Quota",
    quotaGroup: "Quota Group",
    jobMetrics: "Job Metrics",
    uploadSample: "Upload",
    noQuotaGroups: "Study has no existing quota groups.",
    selectQuotaGroup: "Select Quota Group",
    createNewQuotaGroup: "Create New",
    gotoQuotaGroups: "Goto Quota Groups",
    //validateSample: "Validate",
    // processSample: "Process Sample",
    // verifySample: "Verify Sample",
    //beginSurveying: "Begin Surveying",
    inField: "Fieldwork",
    fieldworkComplete: "Complete",
    status: {
      none: "None",
      uploaded: "Uploaded",
      processing: "Processing"
    }
  },
  sampleFileExplorerView: {
    sampleFile: "Sample File:"
  },
  sampleVerificationView: {
    sampleFile: "Sample File:",
    usableSample: "Usable Sample",
    tags: "Tags",
    quotaCounts: "Quota Counts",
    modelCounts: "Model Counts",
    actualDaysInService: "Actual Days In Service",
    loaded: "Loaded",
    scrubbed: "Scrubbed",
    mailDistribution: "Minimum Invite Date",
    usable: "Usable"
  },
  account: {
    signOut: "Sign Out",
    signIn: "Sign In"
  },
  quotaEditor: {
    editQuotaDef: "Edit Quota Definition",
    definition: "Definition",
    name: "Name",
    quota: "Quota",
    save: "Save",
    cancel: "Cancel"
  },
  quotaGroups: {
    rename: "Rename",
    delete: "Delete",
    newQuotaGroup: "",
    noQuotaGroups: "There are no quota groups defined yet.",
    addQuotaGroup: "Add Quota Group"
  },
  quotaTable: {
    noQuotas: "There are no quotas defined for this quota group",
    newQuota: "New Quota",
    emptyTarget: "NONE"
  },
  customDialog: {
    cancel: "Cancel"
  },
  sampleFileColumnPickerDialog: {
    modifyColumns: "Modify Columns",
    cancel: "Cancel",
    update: "Update"
  },
  sampleFileExplorer: {
    chooseColumns: "Choose Columns",
    count: "Count"
  },
  dataModelerGrid: {
    chooseColumns: "Choose Columns",
    count: "Count"
  },
  waveList: {
    noWaves: "There are no waves available for this study."
  },
  sampleFileList: {
    noSampleFiles: "There are no sample files uploaded to this project."
  },
  sampleFileUpload: {
    selectFileTitle: "Select a file to upload",
    name: "Name",
    sampleFile: "Sample File",
    tags: "Tags",
    cancel: "Cancel",
    upload: "Upload"
  },
  assetFileUpload: {
    selectFileTitle: "Select a file to upload",
    fileName: "Asset Name",
    assetFile: "File(s) to upload",
    cancel: "Cancel",
    upload: "Upload"
  },
  projectCreate: {
    createProjectTitle: "Create New Project",
    projectName: "Project Name",
    description: "Project Description",
    create: "Create",
    projectNameRequired: "Project name is required",
    errorProjectNameExists: "Error: Make sure the project name does not already exist.",
    errorUnknown: "Unknown error creating project"
  },
  studyImport: {
    importStudyTitle: "Import Study from JSON file",
    studyName: "Study Name",
    description: "Study Description",
    bucketRegion: "Storage Bucket Region",
    bigQueryRegion: "BigQuery Region",
    dataflowRegion: "Dataflow Region",
    create: "Import",
    errorUnknown: "Unknown error importing study"
  },
  studyCreate: {
    createStudyTitle: "Create New Study",
    studyName: "Study Name",
    description: "Study Description",
    bucketRegion: "Storage Bucket Region",
    bigQueryRegion: "BigQuery Region",
    dataflowRegion: "Dataflow Region",
    create: "Create",
    studyNameRequired: "Study name is required",
    errorStudyNameExists: "Error: Make sure the study name does not already exist.",
    errorUnknown: "Unknown error creating study"
  },
  studyEdit: {
    editStudyTitle: "Edit Study",
    studyName: "Study Name",
    description: "Study Description",
    bucketRegion: "Storage Bucket Region",
    bigQueryRegion: "BigQuery Region",
    dataflowRegion: "Dataflow Region",
    save: "Save",
    studyNameRequired: "Study name is required",
    errorStudyNameExists: "Error: Make sure the study name does not already exist.",
    errorUnknown: "Unknown error saving study"
  },
  dataProtectionRuleEdit: {
    editDataProtectionRuleTitle: "Edit Data Protection Rule",
    save: "Save",
    retentionDaysRequired: "Retention Days is required",
    studyDataProtectionRuleEdited: "Data Protection Rule Edited",
    errorInvalidValues: "Error: Invalid Data protection rule input",
    errorUnknown: "Unknown error saving Data protection rule"
  },
  dataProtectionRuleCreate: {
    createDataProtectionRuleTitle: "Create New Data Protection Rule",
    save: "Save",
    retentionDaysRequired: "Retention Days is required",
    studyDataProtectionRuleCreated: "Data Protection Rule Created",
    errorInvalidValues: "Error: Invalid Data protection rule input",
    errorUnknown: "Unknown error saving Data protection rule"
  },
  studyDetails: {
    storage: "Storage",
    dataflow: "Dataflow",
    bigQuery: "BigQuery",
    methodology: "Methodology"
  },
  surveyMetadataInfo: {
    surveyMetadataInfoTitle: "Survey Metadata Info"
  },
  studyKeyStaff: {
    keyStaffTitle: "Key Staff",
    noKeyStaff: "There are no key staff assigned to this study yet"
  },
  studyOwner: {
    ownerTitle: "Owner",
    reassign: "Re-Assign",
    noOwner: "No Owner Assigned"
  },
  projectKeyStaff: {
    keyStaffTitle: "Key Staff",
    noKeyStaff: "There are no key staff assigned to this project yet"
  },
  domainStudyEdit: {
    editDomainStudyTitle: "Edit Domain",
    save: "Save",
    studyDataProtectionRuleEdited: "Domain Studies Edited"
  }
};
